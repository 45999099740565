import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { mcatLandingPath } from 'my-core/routes';

import LandingPageHeaderTypography from 'my-components/LandingPageHeaderTypography';
import ButtonXL from 'my-elements/ButtonXL';
import Container from 'my-layout/Container';

import HomePageHeroAnimation from './HomePageHeroAnimation';
import HomePageHeroSearch from './HomePageHeroSearch';

import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classes = {
  root: ({ breakpoints, constants, palette, spacing }) => ({
    position: 'relative',
    padding: spacing(constants.APPBAR_OFFSET_UNITS + 3, 0, 4),
    backgroundColor: palette.background.dark,
    overflow: 'hidden',

    [breakpoints.only('sm')]: {
      padding: spacing(constants.APPBAR_OFFSET_UNITS + 3, 0, 6),
    },
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: spacing(6),
    },

    [breakpoints.up('md')]: {
      padding: spacing(constants.APPBAR_OFFSET_UNITS + 12.5, 0, 10),
    },
  }),
  container: ({ breakpoints, palette, spacing }) => ({
    padding: spacing(0, 5),

    [breakpoints.up('md')]: {
      position: 'inherit', // so z-index is valid
      zIndex: 1,
      padding: spacing(10, 5),
      background: `linear-gradient(40deg, ${palette.background.dark} 35%, ${alpha(palette.background.dark, 0)} 65%)`,
    },
  }),
  copySection: ({ breakpoints, palette, spacing }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: spacing(4),

    color: palette.common.white,
    width: '100%',
    maxWidth: 550,

    [breakpoints.only('sm')]: {
      margin: 'auto',
    },

    [breakpoints.up('md')]: {
      maxWidth: 740,
      flex: '0 0 520px',
    },
  }),
  animation: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      left: '15%',
      transform: 'translateY(-50%)',
    },
    [breakpoints.up('lg')]: {
      left: '25%',
    },
    [breakpoints.up('xl')]: {
      left: '35%',
    },
  }),
};

export default function HomePageHero({ onPlayVideo }) {
  const isMobile = useMediaQuery('(max-width: 600px');
  const ButtonComponent = isMobile ? Button : ButtonXL;

  return (
    <div css={classes.root}>
      <Container css={classes.container}>
        <div css={classes.copySection}>
          <LandingPageHeaderTypography type="banner">Better Grades, Less&nbsp;Studying</LandingPageHeaderTypography>
          <Typography fontSize={{ sm: 'body3.fontSize' }} variant="body1">
            Simplify your toughest courses with Wizeprep
          </Typography>
          <ButtonComponent
            color="inherit"
            onClick={() => {
              landingPageCTAClicked('hero', 'watch_how_it_works');
              onPlayVideo(true);
            }}
            size="large"
            startIcon={<FontAwesomeIcon icon={faPlay} />}
            style={{ color: 'white' }}
            variant="outlined"
          >
            See How It Works
          </ButtonComponent>
          <HomePageHeroSearch />
          <Typography>
            Looking for{' '}
            <MuiLink
              color="inherit"
              component={Link}
              onClick={() => landingPageCTAClicked('hero', 'mcat')}
              to={mcatLandingPath()}
            >
              MCAT Prep?
            </MuiLink>
          </Typography>
        </div>
      </Container>
      <HomePageHeroAnimation css={classes.animation} />
    </div>
  );
}
