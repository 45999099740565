import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { livePrepSchoolPath, schoolPath } from 'my-core/routes';
import { pluralize } from 'my-utils';

import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const classes = {
  root: ({ palette, shadows, shape, spacing }) => ({
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    gap: spacing(0.25),
    padding: spacing(3.25, 2, 2),

    backgroundColor: palette.background.paper,
    borderRadius: shape.borderRadiusSm,
    boxShadow: shadows[3],

    '&:hover': { boxShadow: shadows[6] },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: spacing(1.25),
      backgroundColor: palette.primary.main,
      borderTopRightRadius: spacing(1.5),
      borderTopLeftRadius: spacing(1.5),
    },
  }),
  root_alt: ({ palette }) => ({
    '&:before': { backgroundColor: palette.secondary.main },
  }),
};

function SchoolCard(props) {
  const { loading: isLoading, school, type } = props;

  const buttonProps =
    isLoading ? {} : { component: Link, to: type === 'livePrep' ? livePrepSchoolPath(school) : schoolPath(school) };

  const overlineItems = [school.city, school.region_code, school.country_code].filter(Boolean);
  const hasOverline = isLoading || overlineItems.length > 0;

  const detailItems = [
    school.type &&
      school.country_code &&
      (school.type !== 'undergrad' ? 'High School'
      : school.country_code === 'CA' ? 'University'
      : 'College'),
    school.stats?.courses?.high_priority > 0 && pluralize(school.stats.courses.high_priority, 'Course'),
  ].filter(Boolean);
  const hasDetails = isLoading || detailItems.length > 0;

  return (
    <ButtonBase css={[classes.root, school.type !== 'undergrad' ? classes.root_alt : '']} {...buttonProps}>
      {hasOverline && (
        <Typography color="textSecondary" variant="overline">
          {isLoading ?
            <Skeleton variant="text" width="25%" />
          : overlineItems.join(', ')}
        </Typography>
      )}
      <Typography variant="h6">
        {isLoading ?
          <Skeleton variant="text" width="90%" />
        : school.name}
      </Typography>
      {hasDetails && (
        <Typography color="textSecondary" variant="body2">
          {isLoading ?
            <Skeleton variant="text" width="50%" />
          : detailItems.join(' • ')}
        </Typography>
      )}
    </ButtonBase>
  );
}

SchoolCard.propTypes = { school: PropTypes.object.isRequired };
export default SchoolCard;
