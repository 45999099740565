import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import LandingPageItemList from 'my-components/LandingPageItemList';
import SchoolCard from 'my-components/SchoolCard';

import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { toggleContactUsForm } from 'my-actions/AppStateActions';
import { searchSchools } from 'my-actions/SchoolActions';

const useStyles = makeStyles(
  ({ breakpoints, spacing }) => ({
    empty: {
      padding: spacing(2),

      '& > p': { fontSize: 20 },
    },
    [breakpoints.up('sm')]: {
      empty: { padding: spacing(6) },
    },
  }),
  { name: 'LandingPageSchools' },
);
const LandingPageSchools = forwardRef(function LandingPageSchools(props, ref) {
  const { filter: filterProp, title = 'Popular Schools', type } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  return (
    <LandingPageItemList
      defaultFilter={filterProp}
      empty={
        <Paper className={classes.empty} elevation={3}>
          <Typography align="center" variant="h5">
            That’s strange, we couldn’t find your school.
          </Typography>
          <Typography align="center" variant="body1">
            <Link component="button" onClick={() => dispatch(toggleContactUsForm())}>
              <b>Message us</b>
            </Link>{' '}
            and we&apos;ll help you find it or a relevant course.
          </Typography>
        </Paper>
      }
      emptyFilter={{ has_high_priority_courses: true, order_by: 'loc_and_popularity' }}
      fetchAction={searchSchools}
      fetchKey="landing_page_schools"
      fetchParams={{
        fields: {
          school: ['name', 'short_name', 'slug', 'type', 'country_code', 'region_code', 'city', 'stats'],
        },
        order_by: 'user_count',
        order: 'desc',
      }}
      ItemComponent={SchoolCard}
      itemComponentProps={{ type }}
      itemEntity="school"
      ref={ref}
      searchPlaceholder="Search schools"
      storeKey="schools"
      title={title}
    />
  );
});

LandingPageSchools.propTypes = {
  title: PropTypes.string,
};
export default LandingPageSchools;
