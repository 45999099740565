import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import getLibraryCourses from 'my-core/get-library-courses';
import { useCurrentUser } from 'my-core/hooks';
import { coursesPath } from 'my-core/routes';

import SubjectBar from 'my-components/SubjectBar';
import { Container } from 'my-layout';

import Typography from '@mui/material/Typography';

const Root = styled('section')(({ theme: { breakpoints, palette, spacing } }) => ({
  padding: spacing(4, 0, 2),

  [breakpoints.up('sm')]: {
    padding: spacing(10, 0, 4),
  },

  '& .hpsb-subject-bar': {
    margin: spacing(2, 'auto', 0),
    position: 'relative',
    '& > div': { paddingLeft: '5%', paddingRight: '5%' },
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '20%',
      zIndex: 1,
      pointerEvents: 'none',
    },
    '&:before': {
      left: 0,
      background: `linear-gradient(to right, ${palette.background.paper}, ${alpha(palette.background.paper, 0)})`,
    },
    '&:after': {
      right: 0,
      background: `linear-gradient(to right, ${alpha(palette.background.paper, 0)}, ${palette.background.paper})`,
    },
  },
  '& .hpsb-button': {
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(0, 1),
    [breakpoints.down('sm')]: { '& > *': { width: '100%' } },
  },
}));

export default function HomePageSubjectBar() {
  const currentUser = useCurrentUser();

  const libraryCourses = useSelector(state => getLibraryCourses(state.courses.status.library_courses));
  const subjects = useMemo(() => {
    const ctryCourses = libraryCourses[currentUser.country_code === 'CA' ? 'CA' : 'US'];
    return Object.keys({ ...ctryCourses?.undergrad, ...ctryCourses?.high_school });
  }, [currentUser.country_code, libraryCourses]);

  return (
    <Root>
      <Container>
        <Typography align="center" component="h2" gutterBottom typography={{ xs: 'h5', sm: 'h3' }}>
          Expert Guidance for Every&nbsp;Subject
        </Typography>
        <Typography align="center">Find study help for your most difficult subjects</Typography>
      </Container>
      <div className="hpsb-subject-bar">
        <SubjectBar
          getPath={subject => coursesPath({ school_type: 'undergrad', subject })}
          includeAllSubjects
          subjects={subjects}
          value="XXX" // don't show any in selected state
          variant="large"
        />
      </div>
    </Root>
  );
}
